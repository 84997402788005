import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { getUserTokenFromCookie } from "@aether/shared/services/util-service";
import {
  _deleteCart,
  _updateCart,
  _getCart,
} from "@aether/shared/services/cart-service";
import { useAuth } from "@aether/shared/context/auth";

export const getCartKey = "get-cart-key";

type useCartProps = {
  onUpdateSuccess?: () => void,
  onUpdateError?: () => void
}


export default function useCart(props: useCartProps) {
  const { user, isLoading } = useAuth();

  const queryClient = useQueryClient();

  const fetchCart = useQuery({
    queryFn: async () => await _getCart(getUserTokenFromCookie()),
    enabled: !isLoading && Boolean(user),
    queryKey: [getCartKey],
    staleTime: 0,
  });

  const updateCart = useMutation({
    mutationFn: async ({
      photoId,
      quantity,
    }: {
      photoId: number;
      quantity: number;
    }) => await _updateCart(getUserTokenFromCookie(), photoId, quantity),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [getCartKey] });
      props.onUpdateSuccess?.();
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: [getCartKey] });
      props.onUpdateError?.();
    },
  });

  const deleteCart = useMutation({
    mutationFn: async ({ photoId }: { photoId: number }) =>
      await _deleteCart(getUserTokenFromCookie(), photoId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [getCartKey] });
    },
  });

  return { fetchCart, updateCart, deleteCart };
}
