import { cn } from "@/lib/utils";
import Link from "next/link";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  Mail,
  Phone,
  YoutubeIcon,
} from "lucide-react";

export default function Footer() {
  return (
    <footer className={cn("w-screen bg-stone-100")}>
      <div className="container p-5">
        <div className="flex flex-col gap-5">
          <div className="flex md:hidden items-start">
            <div className="grid grid-cols-2 ">
              <div className="flex flex-col gap-1">
                <strong>About us</strong>
                <div className="grid gap-1.5">
                  <Link className="text-xs" href="/about-us#vision">
                    Vision
                  </Link>
                  <Link className="text-xs" href="/about-us#mision">
                    Mission
                  </Link>
                  <Link className="text-xs" href="/">
                    Our Story
                  </Link>
                </div>
              </div>
            </div>
            <div className="space-y-1.5">
              <strong>Contact us </strong>
              <a
                href="tel:+63976423267"
                className="flex gap-2 text-xs items-center"
              >
                <Phone className="h-5" /> +63 976 423 2671
              </a>
              <a
                href="mailto:team@aetherlenz.com"
                className="flex gap-2 text-xs items-center"
              >
                <Mail className="h-5" /> team@aetherlenz.com
              </a>
            </div>
          </div>
          <div className="flex justify-between items-end">
            <div>
              <p className="py-1 text-xs">Follow us</p>
              <div className="flex gap-2 items-end">
                <a
                  href="https://www.facebook.com/people/AetherLenz/61558055862836"
                  target="_blank"
                >
                  <FacebookIcon className="text-white bg-aether-blue h-7 w-7 aspect-square rounded-full p-1" />
                </a>
                <a href="https://www.instagram.com/aetherlenz" target="_blank">
                  <InstagramIcon className="text-white bg-aether-blue h-7 w-7 aspect-square rounded-full p-1" />
                </a>
                <a>
                  <LinkedinIcon className="text-white bg-aether-blue h-7 w-7 aspect-square rounded-full p-1" />
                </a>
                <a>
                  <YoutubeIcon className="text-white bg-aether-blue h-7 w-7 aspect-square rounded-full p-1" />
                </a>
              </div>
            </div>
            <div className="hidden md:flex items-start">
              <div className="grid grid-cols-3 gap-20 col-span-3">
                <div className="flex flex-col gap-1">
                  <strong>About us</strong>
                  <div className="grid gap-1.5">
                    <Link className="text-xs" href="/about-us#vision">
                      Vision
                    </Link>
                    <Link className="text-xs" href="/about-us#mision">
                      Mission
                    </Link>
                    <Link className="text-xs" href="/">
                      Our Story
                    </Link>
                  </div>
                </div>
              </div>
              <div className="space-y-1.5">
                <strong>Contact us </strong>
                <a
                  href="tel:+63976423267"
                  className="flex gap-2 text-xs items-center"
                >
                  <Phone className="h-5" /> +63 976 423 2671
                </a>
                <a
                  href="mailto:team@aetherlenz.com"
                  className="flex gap-2 text-xs items-center"
                >
                  <Mail className="h-5" /> team@aetherlenz.com
                </a>
              </div>
            </div>
            <p className="text-sm">© 2024 AetherLenz</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
