"use client";
import Image from 'next/image';
import { usePathname } from 'next/navigation'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { Logo } from "@/components/atoms/logo";
import AuthButton from "@/components/molecules/auth-button";
import { PresignupButton } from "@/components/molecules/presignup-button";

import { useAuth } from "@aether/shared/context/auth";
import { CartDrawer } from "../molecules/cart-sheet";
import { Camera, Gem, Library, Minus, Plus, SquareArrowDown, SquareArrowUp, X } from "lucide-react";
import useWallet from "@/hooks/use-wallet";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { usePayment } from "@/hooks/use-payment";
import { useRouter } from "next/navigation";
import Link from "next/link";
import { useState } from 'react';
import { cn } from '@/lib/utils';
import { Skeleton } from '../ui/skeleton';
import { Separator } from '../ui/separator';

export function Token() {
  const {
    fetchWallet: { data, isLoading: isWalletTokenLoading },
  } = useWallet();
  const { executePayment } = usePayment();
  const router = useRouter();
  const [tokens, setTokens] = useState(4)
  const [isLoading, setLoading] = useState(false)

  return <Dialog>
    <DialogTrigger className="p-0 my-0">
      <div className="hidden sm:flex items-center rounded-lg border divide-x p-0 bg-white">
        <div className="flex items-center justify-center p-1.5">
          <Gem className="text-green-600 h-6 w-6" />
        </div>
        <Separator orientation="vertical" className="h-full" />
        {
          isWalletTokenLoading ? <Skeleton className=" h-5 w-[2ch] bg-white" /> : <p className="font-semibold text-sm p-1.5">
            {data?.token ?? null}
          </p>
        }
      </div>

      <div className="flex sm:hidden items-center rounded-lg border divide-x p-0 bg-white">
        {
          isWalletTokenLoading ? <Skeleton className=" h-5 w-[2ch] bg-white" /> : <p className="text-sm p-1.5">
            {data?.token ?? null}
          </p>
        }
        <Separator orientation="vertical" className="h-full" />
        <div className="flex items-center justify-center p-1.5">
          <Gem className="text-green-600 h-6 w-6" />
        </div>
      </div>
    </DialogTrigger>
    <DialogContent className="max-w-xs p-0 overflow-hidden gap-0 rounded-lg">
      <DialogHeader className="p-3 bg-stone-100 border-b-1 m-0">
        <DialogTitle className="font-semibold">
          AetherLenz Token
        </DialogTitle>
      </DialogHeader>
      <div className="p-3 grid  gap-2">
        <div className="flex items-center gap-1">
          <p className="text-xs text-center">
            You currently have
          </p>
          <span className="flex items-center gap-1 text-sm justify-center">
            {data?.token} <Gem className="text-green-500 h-4 w-4" />
          </span>
        </div>
        <hr />
        <div className="flex p-2">
          <div className="grid gap-1.5 w-full">
            <span className="flex justify-between">
              <p className="text-xs">
                Buy tokens
              </p>
              <p className="text-xs px-1.5 py-0.5 bg-green-600 text-white">Total: ₱{tokens * 25}</p>
            </span>

            <div className="flex gap-2">
              <span className="flex flex-col items-center justify-center border p-1 rounded-lg">
                <button onClick={() => setTokens(prev => prev + 1)}>
                  <Plus className="h-4" />
                </button>
                <button onClick={() => setTokens(prev => prev <= 1 ? 1 : prev - 1)} >
                  <Minus className="h-4" />
                </button>
              </span>
              <span
                className="h-12 text-lg flex items-center gap-1 relative bg-zinc-100 px-5"
              >
                {tokens} <X className="h-4 w-4" /> <Gem className="text-green-600" />
              </span>
              <Button className="w-full border py-1 text-center h-12 bg-aether-blue hover:bg-aether-blue/90"
                disabled={isLoading}
                onClick={async (event) => {
                  event.preventDefault();
                  setLoading(true);
                  try {
                    const res = await executePayment.mutateAsync(tokens);
                    router.push(res.checkout_url);
                  } catch (e) {
                    console.log(e);
                  }
                  setLoading(false);
                }}
              >
                Checkout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </DialogContent>
  </Dialog >;
}


export function Header2() {
  const pathname = usePathname()
  const { user, isLoading } = useAuth();

  return <header className="flex items-center justify-between p-5">
    <Link href="/" className="text-sm">
      <div className="hidden sm:flex items-center gap-0.5">
        <Image
          src="/aether-logo.png"
          alt="Aether Logo"
          width={400}
          height={400}
          className="h-7 w-7"
        />
        <Image
          src="/aether-logo-text.png"
          alt="Aether Logo"
          width={400}
          height={400}
          className="h-5 w-auto"
        />
      </div>
      <Image
        src="/logo.png"
        alt="Aether Logo"
        width={400}
        height={400}
        className="sm:hidden h-7 w-auto"
      />
    </Link>
    <nav className="flex items-center gap-2">
      {user ?
        <span className="hidden sm:flex items-center gap-1">
          <Link href="/search">
            <button className="p-1.5 rounded-lg border aspect-square bg-whit" disabled={pathname === "/search"}>
              <Camera className={cn("h-6 w-6", pathname === "/search" ? "text-zinc-300" : "")} />
            </button>
          </Link>
          <Link href="/my-photos">
            <button className="p-1.5 rounded-lg border aspect-square bg-white" disabled={pathname === "/my-photos"}>
              <Library className={cn("h-6 w-6", pathname === "/my-photos" ? "text-zinc-300" : "")} />
            </button>
          </Link>
          <CartDrawer />
          <Token />
        </span>
        : null}
      <AuthButton />
    </nav>

    {user ?
      <nav className="fixed bottom-[4%] right-3 flex flex-col items-end gap-1.5 sm:hidden z-50">
        <Link href="/search">
          <button className="p-1.5 rounded-lg border aspect-square bg-white" disabled={pathname === "/search"}>
            <Camera className={cn("h-6 w-6", pathname === "/search" ? "text-zinc-300" : "")} />
          </button>
        </Link>
        <Link href="/my-photos">
          <button className="p-1.5 rounded-lg border aspect-square bg-white" disabled={pathname === "/my-photos"}>
            <Library className={cn("h-6 w-6", pathname === "/my-photos" ? "text-zinc-300" : "")} />
          </button>
        </Link>
        <CartDrawer />
        <Token />
      </nav>
      : null}
  </header>
}

export function NavigationBar() {
  const { user, isLoading } = useAuth();
  const [isScrolled, setIsScrolled] = useState(true)

  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y >= prevPos.y
    if (isShow !== isScrolled) setIsScrolled(isShow)
  }, [isScrolled])

  return isLoading ? null : <div
    className={cn("fixed bottom-2 left-1/2 -translate-x-1/2 z-50 flex gap-3 justify-between items-center bg-zinc-950 rounded-full px-6 transition-all duration-300 ease-in-out h-16 w-fit", isScrolled ? "visible" : "invisible opacity-0")}
  >
    {user ?
      <>
        <Token />
        <CartDrawer />
        <AuthButton />
      </>
      :
      <>
        <Link href="/register">
          <Button variant="link" className="text-white text-sm">
            Register
          </Button>
        </Link>
        <Link href="/" className={`text-white transition-all duration-300 ${isScrolled ? 'text-lg' : 'text-base'}`}>
          AetherLenz
        </Link>
        <Link href="/login">
          <Button variant="link" className="text-white text-sm">
            Login
          </Button>
        </Link>
      </>
    }
  </div>
}

export default function Header() {
  const { user, isLoading } = useAuth();

  return (
    <header className="container w-full py-2 flex items-center justify-between">
      <Logo />
      <nav className="flex gap-4 items-center">
        {user && !isLoading ? (
          <div className="flex items-center gap-3">
            <Token />
          </div>
        ) : !isLoading && !user ? (
          <PresignupButton />
        ) : null}

        <AuthButton />
      </nav>
    </header>
  );
}
