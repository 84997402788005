"use client";
import { getCookie, setCookie, removeCookie } from "typescript-cookie";
import env from "../env";

export function getUserTokenFromCookie() {
  return getCookie(`aether-${env.NEXT_PUBLIC_ROLE}`);
}

export function setUserTokenToCookie(token: string) {
  return setCookie(`aether-${env.NEXT_PUBLIC_ROLE}`, token);
}

export function removeUserTokenFromCookie() {
  return removeCookie(`aether-${env.NEXT_PUBLIC_ROLE}`);
}
