"use client";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import type { User, Auth } from "firebase/auth";
import { authenticate, UserSchema } from "../services/auth-service";
import { toast } from "sonner";
import {
  setUserTokenToCookie,
  removeUserTokenFromCookie,
} from "../services/util-service";
import env from "../env";

type AuthContextProps = {
  user: {
    firebaseUser: User;
    userData: UserSchema;
  } | null;
  isLoading: boolean;
};

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  isLoading: true,
});

interface AuthContextProviderProps {
  children: React.ReactNode;
  firebase: { auth: Auth };
}

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
  firebase,
}) => {
  const userRef = useRef<User>();
  const [user, setUser] = useState<AuthContextProps["user"] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuth = useCallback(
    async (firebaseUser: User) => {
      try {
        if (!userRef.current || userRef.current.uid !== firebaseUser.uid) {
          const uid = await firebaseUser.getIdToken();
          setUserTokenToCookie(uid);
          const userData = await authenticate(uid, env.NEXT_PUBLIC_ROLE);
          setUser({ firebaseUser, userData });
          userRef.current = firebaseUser;
        }
      } catch (e) {
        console.error('Authentication error:', e);
        toast.error("Authentication failed.");
        await firebase.auth.signOut();
        removeUserTokenFromCookie();
      } finally {
        setIsLoading(false);
      }
    },
    [firebase.auth]
  );

  useEffect(() => {
    const unsubscribe = firebase.auth.onIdTokenChanged((firebaseUser) => {
      setIsLoading(true);
      if (!firebaseUser) {
        userRef.current = undefined;
        setUser(null);
        removeUserTokenFromCookie();
        setIsLoading(false);
      } else {
        void checkAuth(firebaseUser);
      }
    });

    return () => unsubscribe();
  }, [firebase.auth, checkAuth]);

  useEffect(() => {
    const handle = setInterval(() => {
      const currentUser = firebase.auth.currentUser;
      if (currentUser) {
        void currentUser.getIdToken(true);
      }
    }, 10 * 60 * 1000);

    return () => clearInterval(handle);
  }, [firebase.auth]);

  return (
    <AuthContext.Provider value={{ user, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
